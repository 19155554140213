import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { Provider } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import store from "./app/store";
import Home from "./components/general/Home";
import NavBar from "./components/general/NavBar";
import Grouppy from "./components/grouppy/Grouppy";
import LockdownCY from "./components/lockdownCY/LockdownCY";
import Sudoku from "./components/sudoku/Sudoku";
import theme from "./config/theme";

function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Grid
            container
            style={{ height: "100vh" }}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <NavBar isMobile={isMobile}></NavBar>
            </Grid>
            <Grid
              item
              style={{
                height: "calc(100% - 56px)",
                position: "fixed",
                top: "56px",
              }}
            >
              <Switch>
                <Route path="/sudoku">
                  <Sudoku isMobile={isMobile}></Sudoku>
                </Route>
                <Route path="/grouppy">
                  <Grouppy isMobile={isMobile}></Grouppy>
                </Route>
                <Route path="/lockdownCY">
                  <LockdownCY isMobile={isMobile}></LockdownCY>
                </Route>
                <Route path="/">
                  <Home isMobile={isMobile}></Home>
                </Route>
                <Redirect from="*" to="/" />
              </Switch>
            </Grid>
          </Grid>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
