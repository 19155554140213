import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AndroidIcon from "@material-ui/icons/Android";
import LaunchIcon from "@material-ui/icons/Launch";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { navigate } from "../../actions/index";
import COLOR_PALETTE from "../../config/color-palete";
const styles = {
  root: {
    width: "max-content",
    textAlign: "center",
    margin: "30px",
    overflowX: "hidden",
  },
  appBar: {
    backgroundColor: COLOR_PALETTE.Black,
    color: COLOR_PALETTE.White,
  },
  link: {
    textDecoration: "none",
    color: COLOR_PALETTE.White,
  },
};

class Menu extends React.Component {
  state = {
    isMobile: this.props.isMobile,
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography
          className={classes.link}
          variant={this.state.isMobile ? "body1" : "h6"}
          style={{ textAlign: this.state.isMobile ? "center" : "left" }}
        >
          {" "}
          Projects hosted here:
        </Typography>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            <Link
              to="/grouppy"
              style={{ textDecoration: "none", display: "contents" }}
              onClick={() => {
                this.props.navigate("http://live.patriciamilou.com/grouppy");
              }}
            >
              <ListItemIcon>
                <AndroidIcon color="primary" />
              </ListItemIcon>
              <ListItemText className={classes.link} primary="Grouppy" />
            </Link>
          </ListItem>

          <ListItem button>
            <Link
              to="/lockdownCY"
              style={{ textDecoration: "none", display: "contents" }}
              onClick={() => {
                this.props.navigate("http://live.patriciamilou.com/lockdownCY");
              }}
            >
              <ListItemIcon>
                <AndroidIcon color="primary" />
              </ListItemIcon>
              <ListItemText className={classes.link} primary="LockdownCY" />
            </Link>
          </ListItem>

          <ListItem button>
            <Link
              to="/sudoku"
              style={{ textDecoration: "none", display: "contents" }}
              onClick={() => {
                this.props.navigate("http://live.patriciamilou.com/sudoku");
              }}
            >
              <ListItemIcon>
                <LaunchIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                className={classes.link}
                primary="Sudoku Solver | Backtracking Algorithm"
              />
            </Link>
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.navBar.home,
    path: state.navBar.path,
  };
};
export default connect(mapStateToProps, { navigate })(withStyles(styles)(Menu));
