import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import COLOR_PALETTE from "../../config/color-palete";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Menu from "./Menu";

const styles = {
  root: {
    overflowY: "auto",
    paddingTop: "70px",
    // margin: "50px",
    color: COLOR_PALETTE.White,
    overflow: "hidden",
    width: "100vw",
    height: "100%",
  },
};

class Home extends React.Component {
  state = {
    isMobile: this.props.isMobile,
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid item xs={12} sm={6}>
          <Grid
            container
            justify="center"
            alignItems="center"
            // style={{ width: "max-content", margin: "0 auto" }}
          >
            <Grid item xs={"auto"}>
              <iframe
                src="https://www.patriciamilou.com"
                id={this.state.isMobile ? "iframe-mob" : "iframe"}
                style={{ float: !this.state.isMobile ? "right" : "inherit" }}
              ></iframe>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                margin: !this.state.isMobile ? "0" : "10px",
                textAlign: "center",
              }}
            >
              <Typography
                className={classes.link}
                variant="body1"
                style={{
                  textOverflow: "ellipsis",

                  width: "inherit",

                  // position: "relative",
                  // top: "-60px",
                }}
              >
                Check out my experience at{" "}
                <Link
                  to={{ pathname: "https://www.patriciamilou.com" }}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: COLOR_PALETTE.White,
                  }}
                >
                  www.patriciamilou.com
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Menu isMobile={this.state.isMobile}></Menu>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Home);
