import { AppBar, Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { navigate } from "../../actions/index";
import COLOR_PALETTE from "../../config/color-palete";
const styles = {
  appBar: {
    backgroundColor: COLOR_PALETTE.DarkPurple,
    color: COLOR_PALETTE.White,
  },
  link: {
    textDecoration: "none",
    color: COLOR_PALETTE.White,
  },
};

class NavBar extends React.Component {
  state = {
    isMobile: this.props.isMobile,
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let href = window.location.href;
    this.props.navigate(href);
  }
  render() {
    const { classes } = this.props;
    return (
      <AppBar
        id="appbar"
        position="fixed"
        style={{ top: 0, position: "fixed" }}
        className={classes.appBar}
      >
        <Toolbar
          style={{
            justifyContent: this.state.isMobile ? "center" : "null",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={() => {
              this.props.navigate("http://live.patriciamilou.com");
            }}
          >
            <Typography
              fullwidth
              component={this.state.isMobile ? "h3" : "h2"}
              variant={this.state.isMobile ? "h3" : "h2"}
              style={{
                textShadow: "1px 1px 1px #FFC4D6",
                textAlign: "center",
                fontFamily: "Nickainley",
                color: "#FF83A8",
              }}
            >
              Patricia Milou
            </Typography>
          </Link>
          {/* {!this.props.home ? (
            <>
              <Divider
                orientation="vertical"
                style={{
                  background: COLOR_PALETTE.WhiteWithOpacity,
                  height: "50px",
                }}
                // flexItem
              />
              <Typography
                className={classes.link}
                variant={this.state.isMobile ? "body2" : "body1"}
                style={{ margin: "0 20px", textOverflow: "ellipsis" }}
              >
                {this.props.path}
              </Typography>
            </>
          ) : null} */}
        </Toolbar>
      </AppBar>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.navBar.home,
    path: state.navBar.path,
  };
};
export default connect(mapStateToProps, { navigate })(
  withStyles(styles)(NavBar)
);
