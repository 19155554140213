import { NAVIGATE_HOME, NAVIGATE_NOT_HOME } from "../actions/types";

const INITIAL_STATE = {
  home: true,
  path: null,
};

function navBar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NAVIGATE_HOME:
      return { ...state, home: true, path: null };
    case NAVIGATE_NOT_HOME:
      if (action.payload.includes("sudoku")) {
        return { ...state, home: false, path: "Sudoku Backtracking Algorthm" };
      }
      if (action.payload.includes("grouppy")) {
        return { ...state, home: false, path: "Grouppy" };
      }
      return { ...state, home: true, path: null };
    default:
      return state;
  }
}

export default navBar;
