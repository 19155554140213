import { CardMedia } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import COLOR_PALETTE from "../../config/color-palete";

const styles = {
  root: {
    overflowY: "auto",
    color: COLOR_PALETTE.White,
    overflowX: "hidden",
    width: "100vw",
    margin: "10px",
  },
  link: {
    textDecoration: "none",
    color: COLOR_PALETTE.White,
  },
  video: {
    // height: "40vh",
    // width: "40vh",
    // minHeight: 400,
    // minWidth: 400,
  },
};
const SCard = withStyles({
  root: {
    width: "100%",
    // height: "calc (100vh - 56px)",
    // height: "inherit",
    overflowY: "auto",
    padding: "10px",
  },
  "& .MuiCardContent:last-child": {
    paddingBottom: "0px",
  },
})(Card);
class LockdownCY extends React.Component {
  state = {
    isMobile: this.props.isMobile,
    wireframes_dialog_open: false,
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="flex-start"
          style={{ height: "inherit", overflowY: "auto" }}
          // className={classes.root}
        >
          <Grid
            container
            xs={"AUTO"}
            justify="center"
            alignItems="center"
            style={{
              margin: this.state.isMobile ? "0 5vw" : "0 10vw",
              height: "inherit",
              // padding: this.state.isMobile ? 0 : "10px",
            }}
          >
            <SCard className={classes.card}>
              <CardHeader title="LockdownCY" />
              <CardContent>
                <Grid
                  container
                  spacing={this.state.isMobile ? 0 : 2}
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4}>
                    <CardMedia
                      component="iframe"
                      src="https://www.youtube.com/embed/D550HGF2x0U"
                      allow="autoPlay"
                      className={classes.video}
                    ></CardMedia>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {this.state.isMobile ? (
                      <Typography
                        variant="body1"
                        component="p"
                        style={{ textAlign: "center" }}
                      >
                        <Box fontWeight={300}>
                          Guide to the most recent movement instructions and
                          regulations given by the government of Cyprus in
                          regards to the Covid-19 pandemic.
                        </Box>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        component="p"
                        style={{
                          textAlign: this.state.isMobile ? "center" : "left",
                        }}
                      >
                        <Box fontWeight={300} style={{ marginBottom: 10 }}>
                          Are you not sure where to get approval for movement
                          during the lockdown in Cyprus? We have collected all
                          the most recent information into one application to
                          make life easier for you!
                        </Box>
                        <Box fontWeight={300} style={{ marginBottom: 10 }}>
                          As per government instructions, citizens need to send
                          an SMS to 8998 and receive an approval before going
                          out. This SMS needs to contain the appropriate
                          category, your ID/passport number and your postcode.
                          More information can be found here:
                          <a href={"https://www.pio.gov.cy/coronavirus/eng"}>
                            https://www.pio.gov.cy/coronavirus/eng
                          </a>
                        </Box>
                        <Box fontWeight={300} style={{ marginBottom: 10 }}>
                          Once you open this application, you will be asked to
                          enter your ID/Passport number and postcode. This
                          personal information is only stored locally on your
                          phone. We don’t collect your name, or any other
                          information so there is no way for the application to
                          match your ID/Passport number and postcode to you. By
                          using this application you don’t have to type your
                          ID/passport number and postcode each time you want to
                          send an SMS request. Just choose your category and
                          you’re ready to go! All persons moving during the
                          lockdown must have with them an identity card or
                          passport, for identification purposes in the event of
                          checks by the Competent Authorities. This number
                          should be the one you use with the application.
                        </Box>
                      </Typography>
                    )}
                    {/* <Typography
                      variant="body1"
                      component="p"
                      style={{
                        textAlign: this.state.isMobile ? "center" : "left",
                        marginTop: "10px",
                      }}
                    >
                      <Box fontWeight={300}>
                        LockdownCY V1.0 will soon be available on Google Play
                        Store.
                      </Box>
                    </Typography> */}
                  </Grid>
                </Grid>
              </CardContent>

              {/* <CardActions>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<GetAppIcon />}
                    >
                      <Link
                        to={apk}
                        target="_blank"
                        download
                        className={classes.link}
                      >
                        ANDROID
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </CardActions> */}
            </SCard>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(LockdownCY);
