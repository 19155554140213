const COLOR_PALETTE = Object.freeze({
  White: "#FFFFFF",
  Black: "#000000",
  BlackWithOpacity: "rgba(0, 0, 0, 0.40)",
  WhiteWithOpacity: "rgba(255, 255, 255, 0.30)",
  Transparent: "rgba(255, 255, 255, 0)",
  BlackWithOpacity: "rgba(0, 0, 0, 0.60)",

  DarkPurple: "#08070d",
});
export default COLOR_PALETTE;
