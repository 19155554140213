export class Solver {
  constructor(boardList) {
    this.memory = [];
    this.intermediateSteps = [];
    this.boardList = boardList;
  }

  solve() {
    this.recursivelyCheck(0, 0);
    return this.getBoard();
  }

  getBoard() {
    return this.boardList;
  }

  cloneBoard() {
    return Object.assign(
      [],
      this.boardList.map((row) => Object.assign([], row))
    );
  }
  recursivelyCheck(r, c) {
    while (r >= 0 && c >= 0 && r <= 8 && this.boardList[r][c] !== "") {
      if (c < 9) c++;
      else {
        r++;
        c = 0;
      }
    }
    if (c > 8) {
      r++;
      c = 0;
    }
    if (r > 8) return true;
    for (let n = 1; n < 10; n++) {
      let num = String(n);
      // console.log("working on: ",r,c)
      // console.log("attempting to add ",num);

      // console.log(row.includes(num),col.includes(num),sub.includes(num));
      // this.memory.push([r, c, num]);
      if (this.doesNotBreakBoard(r, c, num)) {
        this.boardList[r][c] = num;
        let tempBoard = this.cloneBoard();
        this.memory.push(tempBoard);
        if (this.recursivelyCheck(r, c + 1)) {
          return true;
        } else {
          // console.log("changed ",r,c," to .")
          this.boardList[r][c] = "";
          let tempBoard = this.cloneBoard();
          this.memory.push(tempBoard);
          // this.memory.push([r, c, "."]);
        }
        // console.log("placed ",num,"at: ",r,c);
      }
    }
    return false;
  }

  isValid() {
    let res = true;
    for (let row = 0; row < this.boardList.length; row++) {
      for (let col = 0; col < this.boardList[row].length; col++) {
        if (this.boardList[row][col] != "") {
          if (this.boardList[row][col] === 0) {
            return false;
          }
          res = res && this.isSingle(row, col, this.boardList[row][col]);
        }
      }
    }
    return res;
  }
  isSingle(r, c, num) {
    let rStart, rEnd;
    if (r >= 0 && r <= 2) {
      rStart = 0;
      rEnd = 2;
    } else if (r >= 3 && r <= 5) {
      rStart = 3;
      rEnd = 5;
    } else if (r >= 6 && r <= 8) {
      rStart = 6;
      rEnd = 8;
    }

    let cStart, cEnd;
    if (c >= 0 && c <= 2) {
      cStart = 0;
      cEnd = 2;
    } else if (c >= 3 && c <= 5) {
      cStart = 3;
      cEnd = 5;
    } else if (c >= 6 && c <= 8) {
      cStart = 6;
      cEnd = 8;
    }

    let subRow = 0;
    for (let i = 0; i < 9; i++) {
      // row
      if (this.boardList[r][i] === num && c != i) return false;

      // col
      if (this.boardList[i][c] === num && r != i) return false;

      // grid
      let offset = i % 3;
      if (offset === 0 && i !== 0) {
        subRow++;
      }
      if (
        this.boardList[subRow + rStart][offset + cStart] === num &&
        subRow + rStart != r &&
        offset + cStart != c
      ) {
        return false;
      }
    }
    return true;
  }
  doesNotBreakBoard(r, c, num) {
    let rStart, rEnd;
    if (r >= 0 && r <= 2) {
      rStart = 0;
      rEnd = 2;
    } else if (r >= 3 && r <= 5) {
      rStart = 3;
      rEnd = 5;
    } else if (r >= 6 && r <= 8) {
      rStart = 6;
      rEnd = 8;
    }

    let cStart, cEnd;
    if (c >= 0 && c <= 2) {
      cStart = 0;
      cEnd = 2;
    } else if (c >= 3 && c <= 5) {
      cStart = 3;
      cEnd = 5;
    } else if (c >= 6 && c <= 8) {
      cStart = 6;
      cEnd = 8;
    }

    let subRow = 0;
    for (let i = 0; i < 9; i++) {
      // row
      if (this.boardList[r][i] === num) return false;

      // col
      if (this.boardList[i][c] === num) return false;

      // grid
      let offset = i % 3;
      if (offset === 0 && i !== 0) {
        subRow++;
      }
      if (this.boardList[subRow + rStart][offset + cStart] === num)
        return false;
    }
    return true;
  }

  areEqual(boardOne, boardTwo) {
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        if (boardOne[i][j] !== boardTwo[i][j]) return false;
      }
    }
    return true;
  }

  getMemory() {
    return this.memory;
  }

  test() {
    let board = [
      ["5", "3", ".", ".", "7", ".", ".", ".", "."],
      ["6", ".", ".", "1", "9", "5", ".", ".", "."],
      [".", "9", "8", ".", ".", ".", ".", "6", "."],
      ["8", ".", ".", ".", "6", ".", ".", ".", "3"],
      ["4", ".", ".", "8", ".", "3", ".", ".", "1"],
      ["7", ".", ".", ".", "2", ".", ".", ".", "6"],
      [".", "6", ".", ".", ".", ".", "2", "8", "."],
      [".", ".", ".", "4", "1", "9", ".", ".", "5"],
      [".", ".", ".", ".", "8", ".", ".", "7", "9"],
    ];
    const expectedBoard = [
      ["5", "3", "4", "6", "7", "8", "9", "1", "2"],
      ["6", "7", "2", "1", "9", "5", "3", "4", "8"],
      ["1", "9", "8", "3", "4", "2", "5", "6", "7"],
      ["8", "5", "9", "7", "6", "1", "4", "2", "3"],
      ["4", "2", "6", "8", "5", "3", "7", "9", "1"],
      ["7", "1", "3", "9", "2", "4", "8", "5", "6"],
      ["9", "6", "1", "5", "3", "7", "2", "8", "4"],
      ["2", "8", "7", "4", "1", "9", "6", "3", "5"],
      ["3", "4", "5", "2", "8", "6", "1", "7", "9"],
    ];
    this.boardList = board;
    this.solve();
    if (this.areEqual(this.boardList, expectedBoard)) {
      console.log("Test passes...");
    } else {
      console.log("Test fails...");
    }
  }
}

// // HARDEST SUDOKU
// const board = new Board([
//   ["8", ".", ".", ".", ".", ".", ".", ".", "."],
//   [".", ".", "3", "6", ".", ".", ".", ".", "."],
//   [".", "7", ".", ".", "9", ".", "2", ".", "."],
//   [".", "5", ".", ".", ".", "7", ".", ".", "."],
//   [".", ".", ".", ".", "4", "5", "7", ".", "."],
//   [".", ".", ".", "1", ".", ".", ".", "3", "."],
//   [".", ".", "1", ".", ".", ".", ".", "6", "8"],
//   [".", ".", "8", "5", ".", ".", ".", "1", "."],
//   [".", "9", ".", ".", ".", ".", "4", ".", "."],
// ]);
// console.log(board.solve());
// console.log(board.getMemory().length);
