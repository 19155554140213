import { NAVIGATE_HOME, NAVIGATE_NOT_HOME } from "./types";

export const navigate = (path) => async (dispatch) => {
  console.log("navigating to " + path);
  if (
    path === "http://localhost:3000/" ||
    path === "http://live.patriciamilou.com"
  ) {
    dispatch({ type: NAVIGATE_HOME });
  } else {
    dispatch({ type: NAVIGATE_NOT_HOME, payload: path });
  }
};
