import { createMuiTheme } from "@material-ui/core/styles";
import COLOR_PALETTE from "./color-palete";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto", "Nickainley"].join(","),
  },
  palette: {
    primary: { main: COLOR_PALETTE.White },
    secondary: { main: COLOR_PALETTE.Black },
    action: {
      disabledBackground: COLOR_PALETTE.WhiteWithOpacity,
      disabled: COLOR_PALETTE.WhiteWithOpacity,
    },
  },
});

export default theme;
