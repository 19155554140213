import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/styles";
import React from "react";
import COLOR_PALETTE from "../../config/color-palete";
import Board from "./Board";

const styles = {
  bg: {
    background: "linear-gradient(0deg, #08070D 10%,#1D1A31 80%, #29263a 100%)",
    width: "100vw",
    height: "100%",
    overflowX: "hidden",
    color: COLOR_PALETTE.White,
    paddingTop: "10px",
  },
  card: {
    margin: "0 auto",
    textAlign: "left",
    color: "#08070D",
    boxShadow: "5px 5px 5px 0px #2b2740",
  },
};
const SCard = withStyles({
  root: {
    width: "100%",
  },
  "& .MuiCardContent:last-child": {
    paddingBottom: "0px",
  },
})(Card);

class Sudoku extends React.Component {
  state = {
    isMobile: this.props.isMobile,
    expanded: false,
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    if (!this.state.isMobile) {
      this.state.expanded = true;
    }
    return (
      <div className={classes.bg}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ height: "inherit", padding: "10px 30px" }}
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            style={{
              textAlign: "center",
              width: "inherit%",
              // marginTop: this.state.isMobile ? "10px" : "40px",
            }}
          >
            <Grid
              container
              direction="row"
              spacing={2}
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                sm={12}
                md={6}
                style={{
                  width: "100%",
                  float: "left",
                }}
              >
                {/* {props.isMobile ? ( */}
                <SCard
                  className={classes.card}
                  style={{
                    margin: this.state.isMobile ? "0" : "0 30px",
                    padding: this.state.isMobile ? 0 : "20px",
                    //   // margin: props.isMobile ? "0 30px" : "0 50px",
                  }}
                >
                  {this.state.isMobile ? (
                    <CardHeader title="Sudoku Solver" />
                  ) : (
                    <CardHeader title="Sudoku Solver | Backtracking Algorithm" />
                  )}
                  <CardContent>
                    <Typography
                      variant="body1"
                      component="p"
                      style={{ textAlign: "left" }}
                    >
                      <Box fontWeight={300}>
                        <a
                          href="https://en.wikipedia.org/wiki/Sudoku"
                          style={{
                            color: COLOR_PALETTE.Black,
                            textDecorationLine: "underline",
                            textDecorationThickness: "from-font",
                          }}
                        >
                          Sudoku
                        </a>{" "}
                        puzzles can be solved by a few algorithms including a
                        backtracking. This method is simple and guarantees a
                        solution, even for the most difficult ones (as long as
                        the board is valid).
                      </Box>
                    </Typography>

                    <Collapse
                      in={this.state.expanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Typography
                        variant="body1"
                        component="p"
                        style={{
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Box fontWeight={400}>
                          How does it work? <span>🤔</span>
                        </Box>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                        <Box fontWeight={300}>
                          1. Start iterating though all the rows and columns in
                          the board. <br></br> 2. Once you find an empty entry:
                        </Box>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        style={{ textAlign: "left", marginLeft: "10px" }}
                      >
                        <Box fontWeight={300}>
                          A. Start iterating though all numbers from 1 - 9.
                          <br></br> B. Before assigning a number, check if that
                          number will break* the board.<br></br>* A board will
                          break if there is more than one occurence of a number
                          in the same row, column, or 3 ✕ 3 table.
                          <br></br> C. If the entry breaks the board, continue
                          with next number.
                          <br></br> D. If not, move to the next column (or row
                          if you just filled in the last column of the row
                          before) and repeat the steps above.<br></br> E. If
                          there is no number from 1-9 that is valid, then you
                          move back to the previous column (or row), choose
                          another number for it and repeat the steps above.
                        </Box>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        style={{
                          textAlign: "left",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Box fontWeight={300}>
                          If you manage to iterate through the entries of the
                          board and assign a correct number to all the empty
                          ones then you have a solution for it. <span>😄</span>
                        </Box>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        style={{
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                      >
                        <Box fontWeight={300}>
                          If not, then your initial board wasn’t valid.{" "}
                          <span>😏</span>
                        </Box>
                      </Typography>

                      <Typography
                        variant="body1"
                        component="p"
                        style={{
                          textAlign: "left",
                          marginTop: "10px",
                        }}
                      >
                        <Box fontWeight={300}>
                          Time complexity: O(9^(n*n))<br></br>Space
                          Complexity: O(n*n)
                        </Box>
                      </Typography>
                    </Collapse>

                    {this.state.isMobile ? (
                      <IconButton
                        style={{
                          color: COLOR_PALETTE.Black,
                          marginTop: "5px",
                          padding: "0",
                          transform: this.state.expanded
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "all 100ms ease-out",
                        }}
                        onClick={() => {
                          this.setState({
                            expanded: !this.state.expanded,
                          });
                        }}
                        aria-expanded={this.state.expanded}
                        aria-label="show more"
                        id="icon_collapsed"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    ) : null}
                  </CardContent>
                  {/* <CardActions> */}

                  {/* </CardActions> */}
                </SCard>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                style={{ width: "100%", float: "right" }}
              >
                <Board isMobile={this.state.isMobile} getBoard></Board>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            style={{
              textAlign: "center",
              height: "fit-content",
              margin: "10px 0",
            }}
          >
            <Typography variant="body1" component="p" style={{}}>
              <Box fontWeight={300}>
                Created with{" "}
                <a
                  href="https://www.linkedin.com/in/hemangkandwal/"
                  style={{
                    color: COLOR_PALETTE.White,
                    textDecorationLine: "underline",
                    textDecorationThickness: "from-font",
                  }}
                >
                  Hemang Kandwal
                </a>{" "}
                <br /> Last Edit: 06/08/2020
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(Sudoku);
