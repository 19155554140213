import React from "react";
import COLOR_PALETTE from "../../config/color-palete";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Solver } from "./Solver";
import Slider from "@material-ui/core/Slider";
import anime from "animejs/lib/anime.es.js";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Alert from "@material-ui/lab/Alert";

const styles = {
  item: {
    width: "100%",
    margin: "0 auto",
  },
  input: {
    textAlign: "center",
  },
};

const STextField = withStyles({
  root: {
    textAlign: "center",
    overflow: "hidden",
    width: "15px",
    padding: 0,
    margin: 0,
    color: COLOR_PALETTE.White,
    "& input": {
      color: COLOR_PALETTE.White,
    },
    "& label.Mui-focused": {
      color: COLOR_PALETTE.White,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLOR_PALETTE.White,
    },
    "& .MuiInput-underline:before": {
      border: 0,
    },
  },
})(TextField);

const getBorderStyles = (rIndex, cIndex) => {
  let styles = {};
  const width = "2px";

  styles["borderBottom"] = `1px solid rgba(255,255,255,0.15)`;
  styles["borderRight"] = `1px solid rgba(255,255,255,0.15)`;

  if (rIndex % 3 === 0 && cIndex % 3 === 0) {
    styles["borderLeft"] = `${width} solid ${COLOR_PALETTE.White}`;
    styles["borderTop"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 0 && cIndex % 3 === 1) {
    styles["borderTop"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 0 && cIndex % 3 === 2) {
    styles["borderTop"] = `${width} solid ${COLOR_PALETTE.White}`;
    styles["borderRight"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 1 && cIndex % 3 === 0) {
    styles["borderLeft"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 1 && cIndex % 3 === 2) {
    styles["borderRight"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 2 && cIndex % 3 === 0) {
    styles["borderLeft"] = `${width} solid ${COLOR_PALETTE.White}`;
    styles["borderBottom"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 2 && cIndex % 3 === 1) {
    styles["borderBottom"] = `${width} solid ${COLOR_PALETTE.White}`;
  } else if (rIndex % 3 === 2 && cIndex % 3 === 2) {
    styles["borderBottom"] = `${width} solid ${COLOR_PALETTE.White}`;
    styles["borderRight"] = `${width} solid ${COLOR_PALETTE.White}`;
  }

  return styles;
};

class Board extends React.Component {
  state = {
    disabled: false,
    currentStep: 0,
    playing: false,
    steps: [],
    board: [...new Array(9)].map(() => [...new Array(9)].fill("")),
    invalid: false,
    solved: false,
  };

  constructor(props) {
    super(props);
    this.initBoard = [...new Array(9)].map(() => [...new Array(9)].fill(""));
  }

  getSolution = () => {
    if (this.state.solved) return;
    let solver = new Solver(this.state.board);
    if (solver.isValid()) {
      let solution = solver.solve();
      let s = solver.getMemory();
      this.setState(
        {
          board: solution,
          disabled: true,
          steps: s,
          solved: true,
        },
        () => {
          anime({
            targets: document.getElementById("buttons"),
            translateY: 1,
          });
          anime({
            targets: document.getElementById("_slider"),
            opacity: 1,
            duration: 5000,
          });
        }
      );
    } else {
      this.setState({ invalid: true });
      // anime({
      //   targets: document.getElementById("invalidAlert"),
      //   opacity: 1,
      //   duration: 1000,
      // });
    }
  };

  handlePlayPause = () => {
    if (this.state.playing) {
      if (this.playingInterval) {
        this.setState(
          {
            playing: false,
          },
          () => {
            clearTimeout(this.playingInterval);
          }
        );
      }
    } else {
      this.setState(
        {
          playing: true,
        },
        () => {
          this.play();
        }
      );
    }
  };

  play = () => {
    if (this.state.currentStep == this.state.steps.length - 1) {
      this.setState({
        currentStep: 0,
      });
    }
    if (this.state.steps[this.state.currentStep + 1]) {
      this.setState(
        {
          board: this.state.steps[this.state.currentStep + 1],
          currentStep: this.state.currentStep + 1,
        },
        () => {
          this.playingInterval = setTimeout(() => {
            this.play();
          }, 1);
        }
      );
    } else {
      this.setState({
        playing: false,
      });
    }
  };

  clear = () => {
    this.setState(
      {
        invalid: false,
        solved: false,
        currentStep: 0,
      },
      () => {
        // anime({
        //   targets: document.getElementById("invalidAlert"),
        //   opacity: 1,
        //   duration: 1000,
        // });
        if (this.playingInterval) {
          clearTimeout(this.playingInterval);
        }
        this.setState(
          {
            board: [...new Array(9)].map(() => [...new Array(9)].fill("")),
            disabled: false,
            playing: false,
          },
          () => {
            anime({
              targets: document.getElementById("buttons"),
              translateY: -1,
            });
            anime({
              targets: document.getElementById("_slider"),
              opacity: 0,
              duration: 5000,
            });
          }
        );
      }
    );
  };

  render() {
    const { classes } = this.props;

    const putNum = (row, col, num) => {
      if (String(num) === "" || String(num).match("[1-9]") != null) {
        let b = Object.assign([], this.state.board);
        let invalid = true;
        b[row][col] = String(num);
        const sudokuBoard = new Solver(b);
        if (sudokuBoard.isValid()) {
          invalid = false;
        }
        this.setState({
          board: b,
          invalid,
        });
      }
    };

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item md={6} xs={12} style={{ marginBottom: "10px" }}>
          <Typography
            variant="body1"
            component="p"
            // style={{ padding: this.props.isMobile ? "0" : "0 130px" }}
            style={{ width: "100%" }}
          >
            <Box fontWeight={300}>
              Place valid numbers from 0 to 9 to create your board and then
              click "Solve" to see its solution.
            </Box>
          </Typography>
        </Grid>

        {this.state.board.map((row, rIndex) => (
          <Grid item spacing={1} sm={12} style={{ width: "100%" }}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.item}
              style={{ width: "100%", textAlign: "center" }}
            >
              {row.map((col, cIndex) => {
                const styles = getBorderStyles(rIndex, cIndex);
                if (cIndex % 3 === 2) {
                }
                if (rIndex % 3 === 2) {
                }
                if (this.props.isMobile) {
                  styles["padding"] = "5px 6px 5px 10px";
                } else {
                  styles["padding"] = "8px 15px 8px 19px";
                }
                if (rIndex < 3 && cIndex > 2 && cIndex <= 5) {
                  styles["backgroundColor"] = "rgba(255,255,255,0.08)";
                } else if (
                  rIndex >= 3 &&
                  rIndex < 6 &&
                  ((cIndex >= 0 && cIndex <= 2) || (cIndex > 5 && cIndex <= 8))
                ) {
                  styles["backgroundColor"] = "rgba(255,255,255,0.08)";
                } else if (
                  rIndex >= 6 &&
                  rIndex < 9 &&
                  cIndex > 2 &&
                  cIndex <= 5
                ) {
                  styles["backgroundColor"] = "rgba(255,255,255,0.08)";
                }
                return (
                  <Grid item style={{ ...styles }}>
                    <STextField
                      placeholder={" ."}
                      value={String(this.state.board[rIndex][cIndex])}
                      inputProps={{
                        textAlign: "center",
                        maxLength: "1",
                        pattern: "[1-9]",
                        // inputMode: "text",
                        // pattern: "/[0-9]/",
                      }}
                      onChange={(event) => {
                        putNum(rIndex, cIndex, event.target.value);
                      }}
                      disabled={this.state.disabled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          style={{
            marginTop: "20px",
            display: this.state.disabled ? "block" : "none",
          }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={9} sm={"auto"} style={{ float: "right" }}>
              <Slider
                disabled={this.state.playing}
                id="_slider"
                style={{
                  width: this.props.isMobile ? "100%" : "300px",
                  opacity: "0",
                }}
                // defaultValue={currentStep}
                step={1}
                // marks
                min={0}
                value={this.state.steps === null ? 0 : this.state.currentStep}
                max={this.state.steps === null ? 0 : this.state.steps.length}
                onChange={(event, val) => {
                  if (this.playingInterval) {
                    clearTimeout(this.playingInterval);
                  }
                  if (this.state.steps[val]) {
                    this.setState({
                      playing: false,
                      board: this.state.steps[val],
                      currentStep: val,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} sm={"auto"} style={{ float: "left" }}>
              <IconButton
                color="primary"
                aria-label="play steps"
                component="span"
                onClick={this.handlePlayPause}
              >
                {this.state.playing ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }} id="buttons">
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid
              item
              md={6}
              xs={12}
              justify={"center"}
              style={{
                display: this.state.invalid ? "block" : "none",
              }}
              id="invalidAlert"
            >
              <Alert severity="error">The board is invalid.</Alert>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: "0px", margin: "0px", padding: "0px" }}
            />
            <Grid item md={3} xs={6}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                style={{ padding: "10px 0px" }}
                onClick={this.clear}
              >
                Clear
              </Button>
            </Grid>
            <Grid item md={3} xs={6}>
              <Button
                disabled={this.state.solved}
                variant="contained"
                color="primary"
                fullWidth
                style={{ padding: "10px 0px" }}
                onClick={this.getSolution}
              >
                Solve
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Board);
