import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { Link } from "react-router-dom";
import wireframes from "../../assets/grouppy_wireframes.png";
import COLOR_PALETTE from "../../config/color-palete";

const styles = {
  root: {
    overflowY: "auto",
    // margin: "50px",
    color: COLOR_PALETTE.White,
    overflowX: "hidden",
    width: "100vw",
    height: "100%",
  },
  link: {
    textDecoration: "none",
    color: COLOR_PALETTE.White,
  },
};
const SCard = withStyles({
  root: {
    width: "100%",
  },
  "& .MuiCardContent:last-child": {
    paddingBottom: "0px",
  },
})(Card);
class Grouppy extends React.Component {
  state = {
    isMobile: this.props.isMobile,
    wireframes_dialog_open: false,
  };
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="flex-start"
          className={classes.root}
        >
          <Grid
            item
            xs={"AUTO"}
            style={{
              margin: this.state.isMobile ? "0 5vw" : "0 10vw",
              // padding: this.state.isMobile ? 0 : "10px",
            }}
          >
            <SCard className={classes.card}>
              <CardHeader title="Grouppy" />
              <CardContent>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ textAlign: "left" }}
                >
                  <Box fontWeight={300}>
                    An automated help when having to deal with large groups.
                    Grouppy can keep track of your shopping list, tasks, and
                    financials.
                  </Box>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ textAlign: "left", marginTop: "10px" }}
                >
                  <Box fontWeight={300}>
                    Grouppy V1.0 is now available for Android.
                  </Box>
                </Typography>
              </CardContent>
              <CardActions>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                >
                  <Link
                    to={apk}
                    target="_blank"
                    download
                    className={classes.link}
                  >
                    {" "}
                    ANDROID
                  </Link>
                </Button> */}

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<GetAppIcon />}
                  onClick={() =>
                    this.setState({ wireframes_dialog_open: true })
                  }
                >
                  <Link
                    to={wireframes}
                    target="_blank"
                    download
                    className={classes.link}
                  >
                    {" "}
                    WIREFRAMES
                  </Link>
                </Button>
              </CardActions>
            </SCard>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Grouppy);
